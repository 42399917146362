<template lang="pug">
FormModal.edit-note-block-modal(title="Edit Note", method="PUT", :endpoint="`blocks/${block_id}`", :data="data", v-bind="$attrs", @success="onSuccess")
  div.ag-block__note-preview.mb-8
    SimpleNote(v-if="isTemplate('simple')", :note="data")
    SimpleNote2(v-else-if="isTemplate('simple2')", :note="data")
    SimpleNote3(v-else-if="isTemplate('simple3')", :note="data")
    SimpleNote4(v-else-if="isTemplate('simple4')", :note="data")
    SimpleNote5(v-else-if="isTemplate('simple5')", :note="data")

  Tabs
    Tab(title="Content")
      InputGroup(name="title", label="Title - optional")
        TextInput(v-model="data.title", textarea, :rows="1")

      MarkdownInput(v-model="data.synopsis", label="Synopsis", name="synopsis")

      InputGroup(
        label="Image - optional",
        name="featured_image",
        description="JPG, PNG, GIF or WEBP. Up to 10 MB."
      )
        ImageInput(v-model="data.featured_image")

      ImageDisplayInput(v-model="data.image_display")

      div(v-if="user.hasPermission('blocks.note_advanced')", class="gap-8 grid grid-cols-2")
        InputGroup(label="URL - optional", name="content_url")
          TextInput(v-model="data.content_url")
        InputGroup(label="Call to Action - optional", name="call_to_action_text")
          TextInput(v-model="data.call_to_action_text")

      InputGroup(label="Accent Color - optional", description="Leave blank to use your brand color. Only works with the second note design. Contact us if you want to update your brand color.")
        ColorInput(v-model="data.accent_color")

      h2.section-title Visibility
      PublishAtInput(v-model="data.published_at")
      ExpiresAtInput(v-model="data.expires_at")

    Tab(title="Design", class="tab--design space-y-8")
      SimpleNote(:note="data", :selected="isTemplate('simple')", @click="selectTemplate('simple')")
      SimpleNote2(:note="data", :selected="isTemplate('simple2')", @click="selectTemplate('simple2')")
      SimpleNote3(v-if="user.hasPermission('blocks.note_advanced')", :note="data", :selected="isTemplate('simple3')", @click="selectTemplate('simple3')")
      SimpleNote4(v-if="user.hasPermission('blocks.note_advanced')", :note="data", :selected="isTemplate('simple4')", @click="selectTemplate('simple4')")
      SimpleNote5(v-if="user.hasPermission('blocks.note_advanced')", :note="data", :selected="isTemplate('simple5')", @click="selectTemplate('simple5')")
</template>

<script>
import { get } from "lodash-es"
import { mapGetters } from "vuex"
import { Tabs, Tab } from "@syntax51/app-kit"
import ExpiresAtInput from "../../../components/Form/ExpiresAtInput.vue"
import PublishAtInput from "../../../components/Form/PublishAtInput.vue"
import ImageDisplayInput from "../../../components/Form/ImageDisplayInput.vue"
import SimpleNote from "../../../pages/Content/Notes/SimpleNote.vue"
import SimpleNote2 from "../../../pages/Content/Notes/SimpleNote2.vue"
import SimpleNote3 from "../../../pages/Content/Notes/SimpleNote3.vue"
import SimpleNote4 from "../../../pages/Content/Notes/SimpleNote4.vue"
import SimpleNote5 from "../../../pages/Content/Notes/SimpleNote5.vue"
import MarkdownInput from "../../../components/Form/MarkdownInput.vue"

export default {
  components: {
    ExpiresAtInput,
    PublishAtInput,
    ImageDisplayInput,
    MarkdownInput,
    SimpleNote,
    SimpleNote2,
    SimpleNote3,
    SimpleNote4,
    SimpleNote5,
    Tab,
    Tabs
  },
  props: {
    block_id: {
      type: Number,
      required: true
    },
    collection_id: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      template: "simple",
      title: "",
      synopsis: "",
      featured_image: null,
      image_display: null,
      content_url: "",
      call_to_action_text: "",
      accent_color: null,
      expires_at: null,
      published_at: null
    }
  }),
  async created() {
    this.data.collection_id = this.collection_id

    let response = await this.$api.get(`blocks/${this.block_id}`)

    this.data.template = get(response, "data.data.template")
    this.data.title = get(response, "data.data.title")
    this.data.synopsis = get(response, "data.data.synopsis")
    this.data.featured_image = get(response, "data.data.featured_image")
    this.data.image_display = get(response, "data.data.image_display")
    this.data.content_url = get(response, "data.data.url")
    this.data.call_to_action_text = get(response, "data.data.call_to_action_text")
    this.data.expires_at = get(response, "data.data.expires_at")
    this.data.published_at = get(response, "data.data.published_at")
    this.data.accent_color = get(response, "data.data.accent_color")
    this.data.collection_ids = response.data.data.collection_ids
  },
  methods: {
    isTemplate(template) {
      return this.data.template === template
    },
    selectTemplate(template) {
      this.data.template = template
    },
    onSuccess(result) {
      this.$notification.success(`Note successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.edit-note-block-modal {
  .tab {
    @apply pt-8 md:pt-12;
  }

  .section-title {
    @apply mb-6 border-b-2 pb-3 text-base;
    @apply md:mb-8;
  }

  * + .section-title {
    @apply mt-6;
    @apply md:mt-8;
    @apply lg:mt-12;
  }

  .grid {
    & + * {
      @apply mt-8;
    }

    .form-inputgroup {
      @apply mb-0;
    }
  }

  .ag-block__note-preview {
    .ag-block--note-simple {
      @apply m-8;
    }
  }

  .tab--design .ag-block__note-wrapper {
    &:first-child {
      @apply p-8;
    }

    &.ag-block--note-selected {
      @apply rounded ring-[3px] ring-secondary ring-offset-4;
    }

    &:not(.ag-block--note-selected) {
      @apply hover:cursor-pointer hover:rounded hover:ring-[3px] hover:ring-gray-200 hover:ring-offset-4;
    }
  }
}
</style>
